import React from "react";
import { Modal, FormControl, Button, FormGroup, ControlLabel } from "react-bootstrap";

export default class TwoFAConfirmCodeModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClosed}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.showLoading && (<h3>Loading...</h3>)}
                    {!this.props.showLoading && (<div>
                        <p>{this.props.helpText}</p>
                        <FormGroup>
                            <ControlLabel>Confirm your decision by entering your 2FA code</ControlLabel>

                            <FormControl
                                maxLength={6}
                                type="text"
                                placeholder="6-number code"
                                value={this.props.confirmCode}
                                onChange={this.props.modalConfirmCodeUpdate}
                            />
                            <p>{!!this.props.status && this.props.status.value}</p>

                        </FormGroup>
                    </div>)}

                </Modal.Body>
                <Modal.Footer>
                    {!this.props.showLoading && (<div>
                        <Button onClick={this.props.onClosed}>Cancel</Button>
                        <Button
                            disabled={!this.props.confirmCode || (this.props.confirmCode && this.props.confirmCode.length !==6) || (this.props.status && this.props.status.code) }
                            bsStyle="primary"
                            onClick={this.props.onConfirm}
                        >
                            Confirm
                        </Button>
                        {this.props.status && this.props.status.code && (<Button onClick={this.props.onClosed} bsStyle="success">Close</Button>)}
                    </div>)}
                </Modal.Footer>
            </Modal>
        );
    }
}
