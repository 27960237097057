import React from "react";
import styled, { css } from "styled-components";
import StyledIcon from "../StyledIcon";

export const InputWrapper = styled.div`
    position: relative;
    margin-bottom: 45px;
    ${p =>
        p.noBottomMargin &&
        css`
            margin-bottom: 0;
        `}
`;

const InputBar = styled.span`
    position: relative;
    display: block;
    width: 100%;
    &:before,
    &:after {
        content: "";
        height: 2px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: rgb(68, 130, 255);
        transition: 0.2s ease all;
    }

    &:before {
        left: 50%;
    }
    &:after {
        right: 50%;
    }
`;
export const InputLabel = styled.label`
    color: #999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    ${p =>
            p.disabled &&
            css`
            top: -20px;
        `}
`;

const Input = styled.input`
    font-size: 14px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
    &:focus {
        outline: none;
    }
    &:focus ~ ${InputLabel}, &:valid ~ ${InputLabel} {
        top: -20px;
        font-size: 14px;
        color: #5264ae;
    }

    &:focus ~ ${InputBar}:before, &:focus ~ ${InputBar}:after {
        width: 50%;
    }
    &:-moz-ui-invalid {
        box-shadow: none;
    }
`;

const WithIconWrapper = styled.div`

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    ${InputWrapper} {
        flex-grow: 9999;
    }
    ${StyledIcon} {
        margin-top: 14px;
        margin-right: 10px;
    }
`;

function MatInput({ label, ...props }) {
    if (props.icon) {
        return (
            <WithIconWrapper>
                <StyledIcon glyph={props.icon} size="medium" color="#999" />
                <MatInput {...props} label={label} icon={undefined} />
            </WithIconWrapper>
        );
    }
    return (
        <InputWrapper className="mat-input-wrapper" noBottomMargin={props.noBottomMargin}>
            <Input {...props} />
            <InputBar />
            <InputLabel disabled={props.disabled}>{label}</InputLabel>
        </InputWrapper>
    );
}

export default MatInput;
